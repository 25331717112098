import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import i18n from './i18n';
import './index.scss';

export interface IMyAddress {
  isInVN: boolean
  province: string
  provinceCode: string
  district: string
  districtCode: string
  ward: string
  address: string
  phone: string
  email: string
}

interface IProps {
  locale?: string
  userId?: string
  defaultAddress?: IMyAddress
  onAddedAddress: (address: IMyAddress) => void
  onCancelAddress: () => void
}

const MyAddress = ({ locale = 'vi', userId, defaultAddress, onAddedAddress, onCancelAddress }: IProps) => {
  const [saving, setSaving] = useState(false)
  const lang = i18n[locale]

  const onSaveAddress = (values: any) => {
    setSaving(true)
    const db = window.firebase.database()
    db.ref(`/address/${userId}`).update(values).then(() => {
      setSaving(false)
      onAddedAddress(values)
    })
  }

  return (
    <Modal
      title={i18n[locale].addAddress}
      visible={true}
      footer={null}
      onCancel={onCancelAddress}
    >
      <Form
        name="address"
        layout="vertical"
        method="post"
        onFinish={onSaveAddress}
        initialValues={defaultAddress}
      >
        <Form.Item name="email" label="Email" rules={[
          {
            type: 'email',
            message: lang.errors.invalidEmail,
          },
        ]}>
          <Input type="email" />
        </Form.Item>
        <Form.Item name="phone" label={lang.phone} rules={[{ required: true, message: lang.errors.emptyPhone }]}>
          <Input />
        </Form.Item>
        <Form.Item name="address" label={lang.address} rules={[{ required: true, message: lang.errors.emptyAddress }]}>
          <Input placeholder={lang.addressPlaceholder} />
        </Form.Item>
        <Form.Item>
          <Button loading={saving} type="primary" htmlType="submit">
            {lang.save}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default MyAddress