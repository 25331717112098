export default {
  vi: {
    isInVN: 'Địa chỉ bạn ở Việt Nam?',
    addAddress: 'Thêm địa chỉ',
    phone: 'Điện Thoại',
    province: 'Tỉnh/Thành Phố',
    selectProvince: 'Chọn tỉnh/thành',
    district: 'Quận/Huyện',
    selectDistrict: 'Chọn Quận/Huyện',
    ward: 'Phường/Xã',
    selectWard: 'Chọn Phường/Xã',
    address: 'Địa chỉ chi tiết',
    addressPlaceholder: 'Tòa nhà A, đường Nguyễn Du',
    errors: {
      emptyName: 'Vui lòng nhập tên!',
      emptyAddress: 'Vui lòng nhập địa chỉ chi tiết!',
      emptyProvince: 'Vui lóng chọn Tỉnh/Thành!',
      emptyPhone: 'Vui lòng nhập số điện thoại',
      emptyDistrict: 'Vui lóng chọn Quận/Huyện!',
      emptyWard: 'Vui lóng chọn Phường/Xã!',
      invalidEmail: 'Địa chỉ email không chính xác!',
    },
    save: 'Lưu',
  },
  'en-us': {
    isInVN: 'Are you in Vietnam?',
    addAddress: 'Add address',
    phone: 'Phone',
    province: 'Provice/City',
    selectProvince: 'Select Provice/City',
    district: 'District',
    selectDistrict: 'Select District',
    ward: 'Ward',
    selectWard: 'Select Ward',
    address: 'Detail address',
    addressPlaceholder: 'Building 1, Nguyen Du street',
    errors: {
      emptyName: 'Please input your name!',
      emptyPhone: 'Please input your phone number',
      emptyAddress: 'Please input your detail address',
      emptyProvince: 'Please choose province/city!',
      emptyDistrict: 'Please choose District!',
      emptyWard: 'Please choose Ward!',
      invalidEmail: 'The input is not valid E-mail!',
    },
    save: 'Save',
  },
}
