import { EditOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Col, Descriptions, InputNumber, List, notification, Row, Tooltip, Typography as T } from 'antd'
import { Link, navigate } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../components/Context/AuthContext'
import CartContext, { CartMeta } from '../components/Context/CartContext'
import Layout from '../components/Layout'
import Login from '../components/Login'
import MenuCategory from '../components/MenuCategory'
import MyAddress, { IMyAddress } from '../components/MyAddress'
import SEO from '../components/SEO'
import gI18n from '../config/i18n'
import { localizedSlug } from '../gatsby/NodeHelpers'
import i18n from '../templates/i18n'
import './index.scss'


const IndexPage = ({ location, pageContext: { locale } }: any) => {
  const { items, totalItems, totalItemsFee, updateQty, removeItem, clearCart } = useContext(CartContext);
  const { user, loading } = useContext(AuthContext)
  const [processing, setProcessing] = useState(false)
  const [showAddAddress, setShowAddAddress] = useState(false)
  const [address, setAddress] = useState<IMyAddress | undefined>(undefined)

  const onPlaceOrder = () => {
    setProcessing(true)
    const db = window.firebase.database()
    const key = db.ref().child('orders').push().key;

    db.ref(`/orders/${key}`).update({
      user: user?.providerData[0],
      items,
      address,
    }).then(() => {
      setProcessing(false)
      clearCart()
      notification.success({
        placement: 'bottomRight',
        message: i18n[locale].orderSuccessTitle,
        description: i18n[locale].orderSuccessDesc,
      })
      navigate(localizedSlug({ lang: locale, uid: '' }))
    });
  }

  const onShowAddAddress = () => {
    setShowAddAddress(true)
  }

  const onAddedAddress = (value: IMyAddress) => {
    setAddress(value)
    setShowAddAddress(false)
  }

  const onCancelAddress = () => {
    setShowAddAddress(false)
  }

  useEffect(() => {
    if (user) {
      const db = window.firebase.database()
      db.ref(`/address/${user.uid}`).once('value').then(snapshot => {
        setAddress(snapshot.val())
      })
    }
  })

  const renderItem = (item: CartMeta) => {
    const onQtyChange = (value: any = 0) => {
      if (value === 0) {
        removeItem(item.id)
      } else {
        updateQty(item.id, value)
      }
    }
    (item.promotions || []).forEach(promotion => {
      if (item.qty >= promotion.from_qty && item.qty <= promotion.to_qty) {
        item.sellPrice = promotion.promotion_sell_price
      }
    })
    const showMarketPrice = (item.marketPrice || 0) > 0

    return (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar shape="square" size={70} src={item.imgUrl} />}
          title={
            <Link
              to={localizedSlug({ lang: locale, uid: `shop/${item.uid}` })}
            >
              <T.Title level={4}>{item.title}</T.Title>
            </Link>
          }
          description={
            <>
              <Row align="middle" gutter={16} justify="start">
                <Col>
                  <T.Title level={4} type="danger">
                    {gI18n[locale].moneySign + item.sellPrice.toLocaleString(locale)}
                  </T.Title>
                </Col>
                {showMarketPrice && (
                  <Col>
                    <T.Text type="secondary" delete={true}>
                      {gI18n[locale].moneySign + item.marketPrice.toLocaleString(locale)}
                    </T.Text>
                    <T.Text strong={true}>
                      {' '}
                        | -{Math.floor(((item.marketPrice - item.sellPrice) / item.marketPrice) * 100)}%
                      </T.Text>
                  </Col>
                )}
              </Row>
              <InputNumber
                key={item.id}
                min={0}
                // max={item.maxQty}
                defaultValue={item.qty}
                onChange={onQtyChange}
              />
            </>
          }
        />
      </List.Item>
    )
  }

  return (
    <Layout location={location} locale={locale}>
      {showAddAddress && <MyAddress locale={locale} defaultAddress={address} userId={user?.uid} onCancelAddress={onCancelAddress} onAddedAddress={onAddedAddress} />}
      <SEO location={location} locale={locale} />
      <MenuCategory type="shop" locale={locale} title={i18n[locale].cart} />
      <Row gutter={[16, 32]}>
        <Col xs={24} sm={16}>
          <Card
            loading={false}
            hoverable={true}
            type="inner"
            title={<T.Title level={4}>{gI18n[locale].shoppingCart}</T.Title>}
            extra={
              <div className="cartProdInfo">
                <T.Text strong={true} type="danger">
                  {totalItems.toLocaleString(locale)}{' '}
                </T.Text>
                <T.Text strong={true}>{gI18n[locale].product}</T.Text>
              </div>
            }
          >
            <List
              loading={false}
              size="large"
              dataSource={items}
              renderItem={renderItem}
            />
          </Card>
        </Col>
        <Col xs={24} sm={8}>
          <Card hoverable={true} loading={loading}>
            {user &&
              <div>
                {address &&
                  <Tooltip title={i18n[locale].editAddress}>
                    <Button onClick={onShowAddAddress} className="mr-5" shape="circle" icon={<EditOutlined />} />
                  </Tooltip>
                }
                <T.Text strong={true}>{i18n[locale].deliveryAddress}:</T.Text>
                {address ?
                  <div className="mt-5">
                    <T.Text>{address.address} {address.isInVN ? `, ${address.ward}, ${address.district}, ${address.province}` : ''}</T.Text>
                    <div>
                      <T.Text>{i18n[locale].phone}: </T.Text>
                      <T.Text strong={true}>{address.phone}</T.Text>
                    </div>
                    <div><T.Text>Email: <a href={`mailto:${address.email}`}>{address.email}</a></T.Text></div>
                  </div>
                  :
                  <Descriptions className="mt-5">
                    <Descriptions.Item>
                      <Button onClick={onShowAddAddress} type="primary" block={true} ghost={true} size="middle">
                        {i18n[locale].addAddress}
                      </Button>
                    </Descriptions.Item>
                  </Descriptions>
                }
              </div>
            }
            <div className="mt-5">
              <T.Text strong={true}>{gI18n[locale].total}:</T.Text>
              <T.Title level={4} type="danger" className="mt-0">
                {gI18n[locale].moneySign + totalItemsFee.toLocaleString(locale)}{' '}
              </T.Title>
            </div>
            <div className="sg-bill-mob">
              <div>
                {user ?
                  <Button danger loading={processing} onClick={onPlaceOrder} disabled={totalItems === 0 || !address} type="primary" block={true} ghost={true} size="large">
                    {gI18n[locale].placeOrder}
                  </Button> : <Login locale={locale} />
                }
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default IndexPage
