import { UserOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import React, { useContext, useState } from 'react'
import AuthContext from '../../components/Context/AuthContext'
import { getAuth, getUIConfig } from '../../firebase'
import FirebaseAuth from '../../firebase/FirebaseAuth'
import i18n from './i18n'
import './index.scss'

const Login = ({ locale = 'vi' }: IProps) => {
  const lang = i18n[locale]
  const [loginVisible, setLoginVisible] = useState(false)
  const { loading } = useContext(AuthContext)

  const handleLoginCancel = () => setLoginVisible(false)
  const onShowLogin = () => setLoginVisible(true)

  return (
    <div className="sg-login">
      {!loading && (
        <Modal
          centered={true}
          title={lang.login}
          visible={loginVisible}
          onCancel={handleLoginCancel}
          onOk={handleLoginCancel}
        >
          <FirebaseAuth locale={locale} uiConfig={getUIConfig()} firebaseAuth={getAuth()} />
        </Modal>
      )}
      <Button
        loading={loading}
        onClick={onShowLogin}
        size="large"
        type="danger"
        block={true}
        ghost={true}
        icon={<UserOutlined />}
      >
        {lang.login}
      </Button>
    </div>
  )
}

interface IProps {
  locale: string
}

export default Login
