import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

// Global ID for the element.
const ELEMENT_ID = 'firebaseui_container'

// Promise that resolves unless the FirebaseUI instance is currently being deleted.
let firebaseUiDeletion = Promise.resolve()

interface Props {
  uiConfig: any
  // The Firebase App auth instance to use.
  firebaseAuth: any
  // Callback that will be passed the FirebaseUi instance before it is
  // started. This allows access to certain configuration options such as
  // disableAutoSignIn().
  uiCallback?: Function
  className?: string
  locale: string
}

const FirebaseAuth = ({ locale, uiConfig, firebaseAuth, uiCallback, className }: Props) => {
  let unregisterAuthObserver: () => void
  let firebaseUiWidget: any
  let userSignedIn: boolean

  useEffect(() => {
    return () => {
      firebaseUiDeletion = firebaseUiDeletion.then(() => {
        unregisterAuthObserver()
        return firebaseUiWidget.delete()
      })
    }
  }, [])

  const onChangeClientState = (newState: any, addedTags: any, removedTags: any) => {
    if (addedTags.scriptTags && addedTags.scriptTags[0]) {
      addedTags.scriptTags[0].onload = () => {
        // Firebase UI only works on the Client. So we're loading the package in `useEffect`
        // So that this works when doing server-side rendering.
        const firebaseui = window.firebaseui
        // Wait in case the firebase UI instance is being deleted.
        // This can happen if you unmount/remount the element quickly.
        firebaseUiDeletion.then(() => {
          // Get or Create a firebaseUI instance.
          firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth)
          if (uiConfig.signInFlow === 'popup') {
            firebaseUiWidget.reset()
          }

          // We track the auth state to reset firebaseUi if the user signs out.
          userSignedIn = false
          unregisterAuthObserver = firebaseAuth.onAuthStateChanged((user: any) => {
            if (!user && userSignedIn) {
              firebaseUiWidget.reset()
            }
            userSignedIn = !!user
          })

          // Trigger the callback if any was set.
          if (uiCallback) {
            uiCallback(firebaseUiWidget)
          }

          // Render the firebaseUi Widget.
          firebaseUiWidget.start('#' + ELEMENT_ID, uiConfig)
        })
      }
    }
  }

  const langUri = locale === 'vi' ? 'vi' : 'en'

  return (
    <>
      <Helmet onChangeClientState={onChangeClientState}>
        <script src={`https://www.gstatic.com/firebasejs/ui/4.7.0/firebase-ui-auth__${langUri}.js`} />
        <link
          type="text/css"
          rel="stylesheet"
          href="https://www.gstatic.com/firebasejs/ui/4.7.0/firebase-ui-auth.css"
        />
      </Helmet>
      <div className={className} id={ELEMENT_ID} />
    </>
  )
}

export default FirebaseAuth
