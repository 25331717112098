export default {
  vi: {
    login: 'Đăng nhập',
    logout: 'Đăng xuất',
  },
  'en-us': {
    login: 'Login',
    logout: 'Logout',
  },
}
